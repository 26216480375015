import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import compose from 'lodash/flowRight'
import _uniqBy from 'lodash/uniqBy'
import { Form,
  Button,
  Layout,
  Popconfirm,
  Select,
  message,
  Collapse,
  Card,
  Icon
} from 'antd'
import { Link } from 'react-router-dom'
import localeGet from '~/utils/localeGet'
import compare from '~/utils/compare'
// import moment from 'moment'
import { inject, observer } from 'mobx-react'
import { withTranslation } from 'react-i18next'
import registrationQuery from '~/graphql/queries/registration.gql'
import removeOrderItemMutation from '~/graphql/mutations/removeOrderItem.gql'
import _get from 'lodash/get'
import _isFunction from 'lodash/isFunction'
import Itag from '~/components/Itag'
import _includes from 'lodash/includes'
import _defaultTo from 'lodash/defaultTo'
import clientAuth from '../utils/client-auth'
import AddOrderItemsModal from '~/components/Orders/AddOrderItemsModal'
import addOrderItemMutation from '~/graphql/mutations/addOrderItem.gql'
import updateOrderMutation from '~/graphql/mutations/updateOrder.gql'
import setQuestionOptionName from '~/utils/setQuestionOptionName'
import adminQuery from '~/graphql/queries/adminUser.gql'
import _sortBy from 'lodash/sortBy'
import '~/styles/global.css'
import '~/styles/Registration.css'
import moment from 'moment-timezone'

// Loading & Error
import Loading from '~/components/Loading'
import Error404 from '~/components/Error/404'
import config from '~/config'

// import VirtualBib  from './VirtualBib'

// const FormItem = Form.Item

// const optionFieldMap = {
//   Open: 'value',
//   Choice: 'itemId',
//   MultiChoice: 'itemIds'
// }

/* const formItemLayout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 14 }
  }
} */
const options1 = { year: 'numeric', month: 'numeric', day: 'numeric' }
const trackingLink = ({ trackingCode, deliveryCarrier = '' }) => {
  const alphafastLink = 'https://www.alphafast.com/th/track?id='
  //const emsbotLink = 'https://emsbot.com/#/?s='
  const kerryLink = 'https://th.kerryexpress.com/th/track/?track='
  const jtLink = 'https://www.jtexpress.co.th/index/query/gzquery.html'
  const flashLink = 'https://www.flashexpress.co.th/tracking/?se='
  const ThailandLink = 'https://track.thailandpost.co.th/?trackNumber='
  let href = `${alphafastLink}${trackingCode}`
  let text = 'alpha'
  if (deliveryCarrier) {
    switch (deliveryCarrier) {
      case 'alpha':
        href = `${alphafastLink}${trackingCode}`
        text = `Alpha Express`
        break
      case 'thaipost':
        href = `${ThailandLink}${trackingCode}`
        text = `EMS`
        break
      case 'kerry':
        href = `${kerryLink}${trackingCode}`
        text = `Kerry Express`
        break
      case 'flash':
        href = `${flashLink}${trackingCode}`
        text = `Flash`
        break
      case 'jt':
        href = `${jtLink}`
        text = `J&T Express`
        break
      default:
        href = `${ThailandLink}${trackingCode}`
        text = `EMS`
        break
    }
  } else if (emsbotTrackingCodeRegex.test(trackingCode)) {
    href = `${ThailandLink}${trackingCode}`
    text = `EMS`
  }
  return { href, text }
  // return <span style={{ fontWeight: 'normal', fontFamily: 'initial' }}>
  //   {`Tracking Code: `}
  //   <a href={href} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'underline'}}>
  //     {trackingCode}
  //   </a>
  //   {` | ด้วย ${deliveryCarrier || text}`}
  // </span>
}

const locale = config.default_locale || 'th'
console.log('locale', locale);

const optionFieldMap = {
  Open: 'value',
  Choice: 'itemId',
  MultiChoice: 'itemIds'
}

const orderStatuses = ['submitted', 'failed', 'payment_pending', 'payment_verifying', 'paid', 'cancelled', 'accepted', 'rejected', 'expired']
@graphql(addOrderItemMutation, {
  name: 'addOrderItem'
})
@graphql(removeOrderItemMutation, {
  name: 'removeOrderItem'
})
@graphql(updateOrderMutation, {
  name: 'updateOrder'
})
@graphql(adminQuery, {
  options: () => ({
    variables: { _id: clientAuth.login()._id },
    fetchPolicy: 'network-only'
  }),
  props: ({ data: { loading, adminUser } }) => ({
    adminLoading: loading,
    admin: adminUser
  })
})
@inject('router')
@observer
class Registration extends Component {
  state = {
    saleType: undefined,
    orderItemsModalVisible: false,
    isMobile: window.innerWidth < 750
  }
  handleOrderItemsModal = (e) => {
    const saleType = e.target.getAttribute('sale-type')
    const orderItemsMasterId = e.target.getAttribute('orderitems-master-id')
    const orderItemsMasterType = e.target.getAttribute('orderitems-master-type')
    this.setState({
      saleType,
      orderItemsModalVisible: true,
      orderItemsMasterId,
      orderItemsMasterType
    })
  }
  handleAddOrderItem = ({orderItem, form}) => {
    this.setState({submitting: true})
    const { data, addOrderItem } = this.props
    addOrderItem({
      variables: {
        record: orderItem
      }
    }).then(res => {
      this.setState({orderItemsModalVisible: false})
      this.setState({submitting: false})
      form.resetFields()
      data.fetchMore({
        updateQuery: (pre, { fetchMoreResult }) => fetchMoreResult
      })
      message.success(`successfully`)
    }).catch(e => {
      console.error(e)
      message.error(e)
      this.setState({submitting: false})
    })
  }
  handleChangeOrderStatus = ({key: status, label: {props: {orderId}}}) => {
    this.setState({submitting: true})
    const { data, updateOrder } = this.props
    updateOrder({
      variables: {
        record: {
          _id: orderId,
          status
        }
      }
    }).then(res => {
      this.setState({submitting: false})
      data.fetchMore({
        updateQuery: (pre, { fetchMoreResult }) => fetchMoreResult
      })
      message.success(`successfully`)
    }).catch(e => {
      console.error(e)
      message.error(e)
      this.setState({submitting: false})
    })
  }
  handleRemoveOrderItem = ({_id}) => {
    this.setState({submitting: true})
    const {removeOrderItem, data} = this.props
    removeOrderItem({
      variables: { _id }
    }).then(res => {
      data.fetchMore({
        updateQuery: (pre, { fetchMoreResult }) => fetchMoreResult
      })
      this.setState({submitting: false})
      message.success(`successfully`)
    }).catch(e => {
      console.error(e)
      message.error(e)
      this.setState({submitting: false})
    })
  }
  render () {
    const { router, admin, adminLoading, match: { params: { eventId } }, t } = this.props
    const systemLocale = this.props.i18n.language
    const {roles} = clientAuth.login()
    const { location } = router
    const { orderId } = location.query
    const {saleType, orderItemsModalVisible, orderItemsMasterId, orderItemsMasterType, isMobile} = this.state
    // const { getFieldDecorator } = form
    // const { refund: refundMethod } = location.query
    const registrationLoading = _get(this.props, 'data.loading')
    const registration = _get(this.props, 'data.registration')
    const ticketItems = _get(this.props, 'data.ticketItems')
    const emptyItems = <div className="mL">-</div>
    if (registrationLoading) return <Loading />
    if (adminLoading) return <Loading />
    if (!registration) return <Error404 />
    const messages = _get(this.props, 'data.registration.messages')
    const event =_get(registration, 'event')
    const shirts =_get(event, 'shirts')
    const shirtTypes =_get(event, 'shirtTypes')
    const trackingCode = ticketItems.length > 0 && ticketItems.map(t => t.trackingCode).filter(n => n)[0]
    const deliveryMessages = messages && messages.filter(m => _get(m, 'type') === 'updateTracking')
    let deliveryTrackings = deliveryMessages && deliveryMessages.map(({ text, createdAt }) => {
      const texts = text.split(' ')
      const trackingCode = _get(texts, [6])
      const deliveryCarrier = _get(texts, [8])
      return { trackingCode, deliveryCarrier, createdAt }
    })
    deliveryTrackings = _get(deliveryTrackings, 'length') === 0 ? null : _uniqBy(deliveryTrackings, 'trackingCode')
    const deliveryProgressColor = deliveryTrackings ? 'green' : 'yellow'
    const dataFields = [{
      title: `${t("registration.eventName")}`,
      key: 'event',
      dataIndex: 'event',
      show: (registration) => _get(registration, 'name.th', ''),
      render: (event) => <div className="mL"> {event.name.th}<br/>({event.name.en}) </div>
    }, {
      title: `${t("registration.participant")}`,
      key: 'participants',
      dataIndex: 'participants',
      show: (participants) => participants.length !== 0,
      render: (participants, record) =>
        <ul>
          {participants.map((participant, index) => {
            let participantLink = `/events/${record.eventId}/participants/${participant._id}`
            const ticket = ticketItems.find(t => `${t.participantId}` === `${participant._id}`)
            if (!_get(ticket, 'ticketType.name.th')) {
              participantLink = `/events/${record.eventId}/participants/${participant._id}/edit`
            }
            return <li key={participant._id}>
              <Link to={participantLink}>
                <Icon type="edit" /> {participant.profile.firstName} {participant.profile.lastName}
              </Link>
              {' '}({participant.profile.email})
              {' '}<i><u>{_get(ticket, 'ticketType.name.th')}</u></i>
              {' '}<b style={{color: 'red'}}>
                <u>{!_get(ticket, 'ticketType.name.th') && 'ไม่พบประเภทการสมัคร!'}</u>
              </b>
              {_get(participant, 'tickets[0].externalCouponCode') && <div>
                {_get(participant, 'tickets[0].ticketType.externalCoupon.type')} code: {_get(participant, 'tickets[0].externalCouponCode')}
              </div>}
              <div>
                <img src={`https://api.qrserver.com/v1/create-qr-code/?data=${_get(participant, 'tickets[0].code')}&size=200x200`}/>
              </div>
            </li>
          })}
        </ul>
    }, {
      title: 'ข้อมูล',
      key: 'info',
      show: () => isMobile,
      render: (registration, record) => {
        return <div>
          <b>Event ID:</b> {record._id} <br/>
          <b>รหัสใบสมัคร:</b> {record.code} <br/>
          <b>ประเภทใบสมัคร:</b> {record.registrationType.name.en}
        </div>
      }
    }, {
      title: 'Event ID',
      key: '_id',
      dataIndex: '_id',
      show: (_id) => !isMobile && _id
    }, {
      title: `${t("registration.registrationId")}`,
      key: 'code',
      dataIndex: 'code',
      show: (code) => !isMobile && code
    }, {
      title: `${t("registration.registrationType")}`,
      key: 'registrationType',
      dataIndex: 'registrationType.name.en',
      show: (registrationType) => !isMobile && registrationType
    }, {
      title: `${t("registration.couponCodes")}`,
      key: 'couponCodes',
      dataIndex: 'couponCodes',
      show: (couponCodes) => couponCodes.map(cc => cc.code).join(', '),
      render: (couponCodes) => couponCodes.map(cc => cc.code).join(', ')
    }, {
      title: `${t("events.edit.application.deliveryOption")}`,
      key: 'deliveryOptionId',
      dataIndex: 'deliveryOptionId',
      show: (deliveryOptionId) => deliveryOptionId,
      render: (deliveryOptionId) => {
        if (!deliveryOptionId) return emptyItems
        const deliveryOptions = _get(registration, 'registrationType.deliveryOptions', [])
        const deliveryOption = deliveryOptions.find(d => d._id === deliveryOptionId)
        return <div className="mL"> {_get(deliveryOption, 'name.th')} <br/> ({_get(deliveryOption, 'name.en')}) </div>
      }

    }, {
      title: 'Tracking Code',
      key: 'trackingCode',
      dataIndex: 'trackingCode',
      show: () => deliveryTrackings,
      render: () => {
        return <ul>
          {/* {ticketItems.map((ticket) => <li key={ticket._id}>{ticket.trackingCode} ({ticket.deliveryCarrier})</li>)} */}
          {deliveryTrackings && deliveryTrackings.map(({ trackingCode, deliveryCarrier, createdAt }) => <div>
            <span className="description">
              {trackingCode} <a
                onClick={() => { navigator.clipboard.writeText(trackingCode) }}
                href={
                  _get(trackingLink({ trackingCode, deliveryCarrier }), 'href')
                }
              ><Icon type="copy" /> ตรวจสอบ </a>
              <i style={{ color: 'rgb(212, 190, 190)' }}>
                {{
                  en: new Intl.DateTimeFormat('en-GB', options1).format(moment(createdAt)),
                  zh: new Intl.DateTimeFormat('en-GB', options1).format(moment(createdAt)),
                  th: new Intl.DateTimeFormat('th-TH', options1).format(moment(createdAt))
                }[locale]}
              </i>
            </span>
          </div>)}
        </ul>
      }
    }, {
      title: `${t('delivery.deliveryAddress')}`,
      key: 'deliveryAddress',
      dataIndex: 'deliveryAddress',
      show: (deliveryAddress) => _get(deliveryAddress, 'street'),
      // show: (deliveryAddress, data) => data.event.isVirtual,
      render: (deliveryAddress) => {
        // const { city, country, locality, postalCode, province, street } = deliveryAddress
        return <div>
          {`${_get(deliveryAddress, 'street')} ${_get(deliveryAddress, 'locality')} ${_get(deliveryAddress, 'city')} ${_get(deliveryAddress, 'country')} ${_get(deliveryAddress, 'province')} ${_get(deliveryAddress, 'postalCode')}`}
        </div>
      }
    }, {
      title: 'ใบกำกับภาษี/ใบเสร็จรับเงิน',
      key: 'invoice',
      dataIndex: 'invoice',
      show: () => _get(registration, 'event.invoiceEnabled'),
      render: (invoice) => {
        return <div>
          {_get(invoice, 'name')} ({_get(invoice, 'type')})
          <br/>{_get(invoice, 'taxId')}
          <br/>{_get(invoice, 'address')}
        </div>
      }
    }, {
      title: `${t("registration.payment")}`,
      key: 'payments',
      dataIndex: 'payments',
      show: (payments) => payments.length !== 0,
      render: (payments) =>
        <ul>
          {payments.map(p => (
            <li key={p._id}>
              {p._id} <Itag type={p.status} text={p.method} />
              { p._id === registration.paymentId && <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />}
              <br/>
            </li>
          ))}
          <li key='viewAllPayment'>
            {<Link to={`/events/${eventId}/registrations/${registration._id}/payments`}>
              view all
            </Link>}
          </li>
        </ul>
    }, {
      title: `${t("registration.order")}`,
      key: 'orderItems',
      dataIndex: 'orderItems',
      show: (orderItems) => {
        const products = _get(registration, 'event.products', []) // .filter(p => p.crossSale)
        // return !((products.length === 0 || orderItems.length === 0))
        return products.length > 0
      },
      render: (orderItems) => {
        const products = _get(registration, 'event.products', []) // .filter(p => p.crossSale)
        if (products.length === 0) return emptyItems
        return <div>
          <ol>
            {orderItems.filter(item => item.masterType === 'Registration').map(item => {
              const openorderitemtype = _get(item, 'product.openorderitemtype')
              const orderItemType = _get(item, 'orderitemtype')
              const quantity = _get(item, 'quantity')
              const price = openorderitemtype ? _get(orderItemType, 'price') || 0 : _get(item, 'product.price') || 0
              const amount = price * quantity
              const sortedOrderItems = _sortBy(_get(item, 'options'), (r) => {
                return _get(r, 'question.order')
              }) || []
              return (<li key={item._id}>
                {item.product.name.th} {localeGet(orderItemType, 'name', locale)}<br/>
                  {{th: "จำนวน:", en: "amount:", id: "jumlah:"}[systemLocale]} {item.quantity} <br/> {{th: "ราคา:", en: "price:", id: "harga:"}[systemLocale]} {amount} {_get(event, 'settings.payment.currency', 'THB')}{' '}
                <ul>
                  {/* {_get(item, 'options', []).map(option => {
                    let value = _get(option, optionFieldMap[option.question.kind], '')
                    value = setQuestionOptionName({kind: option.question.kind, value, items: _get(option, 'question.items'), locale: 'th'})
                    
                    return <li>{option.question.name.en}: {value}</li>
                  })} */}
                  {sortedOrderItems.map(option => {
                    if (_get(option, 'question.inputType') === 'shirtTypes') {
                      return (<li key={option._id}>
                        {localeGet(option, 'question.name', locale)}:{' '}
                        {localeGet(event.shirtTypes.find(st => st._id === option.shirtTypeId), 'name', locale)},&nbsp;
                        {localeGet(event.shirts.find(s => s._id === option.shirtId), 'name', locale)}
                      </li>)
                    }
                    let value = _get(option, optionFieldMap[option.question.kind], '')
                    value = setQuestionOptionName({kind: option.question.kind, value, items: _get(option, 'question.items'), locale})

                    const condition = _get(option, 'question.condition')
                    let condPassed = true
                    if (_get(condition, 'questionId')) {
                      const questions = _get(item, 'product.questions')
                      const condQuestionIndex = questions.findIndex(q => q._id === condition.questionId)
                      const condQuestion = questions[condQuestionIndex]
                      const condOption = sortedOrderItems.find(o => `${o.questionId}` === `${condQuestion._id}`)
                      const condOptionField = {
                        Open: 'value',
                        Choice: 'itemId',
                        MultiChoice: 'itemIds'
                      }[condQuestion.kind]
                      const value = _get(condOption, `${condOptionField}`)
                      condPassed = compare(value, condition.operator, condition.value)
                    }
                    return condPassed && <li key={option._id}>{localeGet(option, 'question.name', locale)}: {value}</li>
                  })}
                </ul>
                {_includes(roles, 'admin') || _includes(admin.abilities, 'product') ? <Popconfirm title="ต้องการยกเลิกสินค้านี้ ?" onConfirm={() => this.handleRemoveOrderItem({_id: item._id})}>
                  <Button icon="delete" className="delete" loading={this.state.submitting}/>
                </Popconfirm> : ''}
              </li>)
            })}
            <p></p>
            {_includes(roles, 'admin') || _includes(admin.abilities, 'product') ? <li>
              <Button icon="plus" className="add" loading={this.state.submitting}
                sale-type='crossSale' orderitems-master-id={registration._id} orderitems-master-type='Registration'
                onClick={this.handleOrderItemsModal}> {t("registration.addorder")} </Button>
            </li> : ''}
          </ol>
          <div>
              {`${t("registration.total")}`}: {orderItems && <Itag type='amount' text={`${
                t('menu.registration.price', {
                  value: {
                    price: orderItems
                    .reduce((sum, item) => {
                      const {quantity} = item
                      const openorderitemtype = _get(item, 'product.openorderitemtype')
                      const orderItemType = _get(item, 'orderitemtype')
                      const price = openorderitemtype ? _get(orderItemType, 'price') || 0 : _get(item, 'product.price') || 0
                      const amount = price * quantity
                      return sum + (amount)
                    }, 0),
                    currency: _defaultTo( _get(event, 'settings.payment.currency', 'THB'), 'THB')
                  },
                  })
                }`} />
              }
          </div>
        </div>
      }
    }, {
      title: `${t("registration.status")}`,
      key: 'status',
      dataIndex: 'status',
      show: (status) => !isMobile && status,
      render: (status) => <div className="mL"> <Itag type={status} text={status} /> </div>
    }, {
      title: `${t("registration.totalAmount")}`,
      key: 'amount',
      dataIndex: 'amount.discounted',
      show: (discounted) => !isMobile && (discounted !== 0),
      render: (discounted) => <div className="mL">
        <Itag type='amount' text={`${discounted} THB`} />
      </div>
    }, {
      // FOR MOBILE
      title: 'สถานะและจำนวน',
      key: 'statusAmount',
      show: (registration, record) => isMobile && (record.status || (record.amount.discounted !== 0)),
      render: (registration, record) => <div>
        <div><Itag type={record.status} text={record.status} /> <Itag type='amount' text={`${record.amount.discounted} THB`} /></div>
      </div>
    }, {
      // FOR MOBILE
      title: 'สินค้าเพิ่มเติมหลังสมัครสมบูรณ์',
      key: 'ordersMobile',
      dataIndex: 'orders',
      show: (orders) => {
        const products = _get(registration, 'event.products', []).filter(p => p.extraSale)
        return isMobile && (!((products.length === 0 || orders.length === 0)))
      },
      render: (orders) => <div>
        {orders.map(order => {

          return <div key={order._id}>
            <div>
                  รหัสสั่งซื้อ {order.code}{' '}
              {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <Select style={{ width: '100%', margin: '10px 0' }}
                labelInValue
                defaultValue={{key: order.status, orderId: order._id}}
                onChange={this.handleChangeOrderStatus}
              >
                {orderStatuses.map(status =>
                  <Select.Option value={status} key={order._id + status}>
                    <Itag type={status} text={status} orderId={order._id}/>
                  </Select.Option>
                )}
              </Select> : <Select style={{ width: '100%', margin: '10px 0' }}
                labelInValue
                defaultValue={{key: order.status, orderId: order._id}}
                onChange={this.handleChangeOrderStatus}
                disabled
              >
                {orderStatuses.map(status =>
                  <Select.Option value={status} key={order._id + status}>
                    <Itag type={status} text={status} orderId={order._id}/>
                  </Select.Option>
                )}
              </Select>}
              <Collapse defaultActiveKey={['1']} >
                <Collapse.Panel header={<div>Payments  <Itag type='amount' text={`${order.amount.discounted} THB`} /> </div>} key="1">
                  {order.payments.map(p => (
                    <div key={p._id} style={{ margin: 5 }}>
                      {p._id} <br /> <Itag type={p.status} text={p.method} />
                    </div>
                  ))}
                  {order.payments.length === 0 && <li>-</li>}
                </Collapse.Panel>
              </Collapse>
            </div>
            <div>
              {order.items.map(item => {
                const itemOptions = _sortBy(_get(item, 'options'), (r) => {
                  return _get(r, 'question.order')
                }) || []
                return <div key={item._id} style={{ padding: '5px 0' }}>
                  {/* {item.product.name.th} */}
                  {itemOptions.map(option => {
                      if (_get(option, 'question.inputType') === 'shirtTypes') {
                        return (<li key={option._id}>
                          {localeGet(option, 'question.name', locale)}:{' '}
                          {localeGet(shirtTypes.find(st => st._id === option.shirtTypeId), 'name', locale)},&nbsp;
                          {localeGet(shirts.find(s => s._id === option.shirtId), 'name', locale)}
                        </li>)
                      }
                      // let value = _get(option, optionFieldMap[option.question.kind], '')
                      // value = setQuestionOptionName({kind: option.question.kind, value, items: _get(option, 'question.items'), locale})
                      // return value && <li key={option._id}>{localeGet(option, 'question.name', locale)}: {value}</li>
                      let value = _get(option, optionFieldMap[option.question.kind], '')
                      value = setQuestionOptionName({kind: option.question.kind, value, items: _get(option, 'question.items'), locale})

                      const condition = _get(option, 'question.condition')
                      let condPassed = true
                      if (_get(condition, 'questionId')) {
                        const questions = _get(item, 'product.questions')
                        const condQuestionIndex = questions.findIndex(q => q._id === condition.questionId)
                        const condQuestion = questions[condQuestionIndex]
                        const condOption = sortedOrderItems.find(o => `${o.questionId}` === `${condQuestion._id}`)
                        const condOptionField = {
                          Open: 'value',
                          Choice: 'itemId',
                          MultiChoice: 'itemIds'
                        }[condQuestion.kind]
                        const value = _get(condOption, `${condOptionField}`)
                        condPassed = compare(value, condition.operator, condition.value)
                      }
                      // console.log({condPassed, value, code: order.code})
                      return condPassed && value && <li key={option._id}>{localeGet(option, 'question.name', locale)}: {value}</li>
                    })}
                      จำนวน {item.quantity} ชิ้น ราคา {item.quantity * item.product.price} THB{' '}
                  {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <Popconfirm title="ต้องการยกเลิกสินค้านี้ ?" onConfirm={() => this.handleRemoveOrderItem({_id: item._id})}>
                    <Button icon="delete" className="delete" loading={this.state.submitting}/>
                  </Popconfirm> : ''}
              </div>})}
              {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <div>
                <Button icon="plus" className="add" loading={this.state.submitting}
                  sale-type='extraSale' orderitems-master-id={order._id} orderitems-master-type='Order'
                  onClick={this.handleOrderItemsModal}> {t("registration.addorder")} </Button>
              </div> : ''}
            </div>
          </div>
      })}
      </div>
    }, {
      title: {th: 'สินค้าเพิ่มเติมหลังสมัครสมบูรณ์', en: 'Additional products '}[systemLocale],
      key: 'orders',
      dataIndex: 'orders',
      show: (orders) => {
        const products = _get(registration, 'event.products', []) // .filter(p => p.extraSale)
        return !isMobile && (!((products.length === 0 || orders.length === 0)))
      },
      render: (orders) => {
        const products = _get(registration, 'event.products', []) // .filter(p => p.extraSale)
        if (products.length === 0 || orders.length === 0) return emptyItems
        return <ul>
          {orders.map(order => (
            <li key={order._id} className={orderId === order._id ? 'highlightOrder' : ''} >
              <div>
                  {{th: "รหัสสั่งซื้อ", en: "order code:"}[systemLocale]} {order.code}{' '}
                {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <Select style={{ width: 168 }}
                  labelInValue
                  defaultValue={{key: order.status, orderId: order._id}}
                  onChange={this.handleChangeOrderStatus}
                >
                  {orderStatuses.map(status =>
                    <Select.Option value={status} key={order._id + status}>
                      <Itag type={status} text={status} orderId={order._id}/>
                    </Select.Option>
                  )}
                </Select> : <Select style={{ width: 168 }}
                  labelInValue
                  defaultValue={{key: order.status, orderId: order._id}}
                  onChange={this.handleChangeOrderStatus}
                  disabled
                >
                  {orderStatuses.map(status =>
                    <Select.Option value={status} key={order._id + status}>
                      <Itag type={status} text={status} orderId={order._id}/>
                    </Select.Option>
                  )}
                </Select>}
                <Collapse defaultActiveKey={['1']} >
                  <Collapse.Panel header={<div>Payments  <Itag type='amount' text={`${order.amount.discounted} THB`} /> </div>} key="1">
                    <ul>
                      {order.payments.map(p => (
                        <li key={p._id}>
                          {p._id} <Itag type={p.status} text={p.method} />
                        </li>
                      ))}
                      {order.payments.length === 0 && <li>-</li>}
                    </ul>
                  </Collapse.Panel>
                </Collapse>
              </div>
              <ol>
                {order.items.map(item => {
                  const openorderitemtype = _get(item, 'product.openorderitemtype')
                  const orderItemType = _get(item, 'orderitemtype')
                  const quantity = _get(item, 'quantity')
                  const price = openorderitemtype ? _get(orderItemType, 'price') || 0 : _get(item, 'product.price') || 0
                  const amount = price * quantity
                  const itemOptions = _sortBy(_get(item, 'options'), (r) => {
                    return _get(r, 'question.order')
                  }) || []
                  return  (<li key={item._id}>
                      {item.product.name.th} <br/>
                      {{th: "จำนวน:", en: "amount:"}[systemLocale]} {item.quantity} <br/> {{th: "ราคา:", en: "price:"}[systemLocale]} {amount} {_get(event, 'settings.payment.currency', 'THB')}{' '}
                      <ul>
                      {itemOptions.map(option => {
                        if (_get(option, 'question.inputType') === 'shirtTypes') {
                          return (<li key={option._id}>
                            {localeGet(option, 'question.name', locale)}:{' '}
                            {localeGet(event.shirtTypes.find(st => st._id === option.shirtTypeId), 'name', locale)},&nbsp;
                            {localeGet(event.shirts.find(s => s._id === option.shirtId), 'name', locale)}
                          </li>)
                        }
                        let value = _get(option, optionFieldMap[option.question.kind], '')
                        value = setQuestionOptionName({kind: option.question.kind, value, items: _get(option, 'question.items'), locale})

                        const condition = _get(option, 'question.condition')
                        let condPassed = true
                        if (_get(condition, 'questionId')) {
                          const questions = _get(item, 'product.questions')
                          const condQuestionIndex = questions.findIndex(q => q._id === condition.questionId)
                          const condQuestion = questions[condQuestionIndex]
                          const condOption = itemOptions.find(o => `${o.questionId}` === `${condQuestion._id}`)
                          const condOptionField = {
                            Open: 'value',
                            Choice: 'itemId',
                            MultiChoice: 'itemIds'
                          }[condQuestion.kind]
                          const value = _get(condOption, `${condOptionField}`)
                          condPassed = compare(value, condition.operator, condition.value)
                          
                        }
                        return condPassed && value && <li key={option._id}>{localeGet(option, 'question.name', locale)}: {value}</li>
                      })}
                      </ul>
                      {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <Popconfirm title="ต้องการยกเลิกสินค้านี้ ?" onConfirm={() => this.handleRemoveOrderItem({_id: item._id})}>
                        <Button icon="delete" className="delete" loading={this.state.submitting}/>
                      </Popconfirm> : ''}
                  </li>)
                })}
                <p/>
                {_includes(roles, 'admin') || _includes(admin.abilities, 'registrationType') ? <li>
                  <Button icon="plus" className="add" loading={this.state.submitting}
                    sale-type='extraSale' orderitems-master-id={order._id} orderitems-master-type='Order'
                    onClick={this.handleOrderItemsModal}> {t("registration.addorder")} </Button>
                </li> : ''}
              </ol>
            </li>
          ))}
        </ul>
      }
    }]
    return <Layout>
      <Layout.Content className="content card-content">
        <h2>{t("registration.registrationId")}: {registration.code}</h2>
        {/* <h3>( เลขที่ใบสมัคร )</h3> */}
        {/* {isMobile && dataFields.map(f => f.show(_get(registration, f.dataIndex), registration) && */}
        {dataFields.map(f => f.show(_get(registration, f.dataIndex), registration) &&
          <Card
            title={f.title}
            size="small"
            style={{marginBottom: 10}}
            key={f.key}
          >
            {(f.render && _isFunction(f.render))
              ? f.render(_get(registration, f.dataIndex), registration)
              : <p>{_get(registration, f.dataIndex)}</p>}
          </Card>
        )}
        {/* <div className="wrap-destops">
          <Form>
            {!isMobile && dataFields.map(f => f.show(_get(registration, f.dataIndex), registration) &&
              <Form.Item key={f.key} label={f.title} {...formItemLayout}>
                {(f.render && _isFunction(f.render))
                  ? f.render(_get(registration, f.dataIndex), registration)
                  : <div className="mL">{_get(registration, f.dataIndex)}</div>
                }
              </Form.Item>)}
          </Form>
        </div> */}
        <Form>
          <Form.Item wrapperCol={{xs: { span: 24 }, sm: { span: 18, offset: 6 }}} >
            <Link to={`/events/${eventId}/registrations/${registration._id}/edit`}>
              <Button className="btn" type="primary" icon="edit">{t("registration.edit")}</Button>
            </Link>
            <Link to={ !orderId ? `/events/${eventId}/registrations/` : `/events/${eventId}/orders/`}>
              <Button className="btn" type="ghost" >{t("registration.previous")}</Button>
            </Link>
          </Form.Item>
        </Form>
        {saleType && <AddOrderItemsModal
          visible={orderItemsModalVisible}
          registration={registration}
          saleType={saleType}
          onCancel={() => this.setState({orderItemsModalVisible: false})}
          masterId={orderItemsMasterId}
          masterType={orderItemsMasterType}
          onAddOrderItem={this.handleAddOrderItem}
        />}
      </Layout.Content>
    </Layout>
  }
}

export default compose(
  graphql(registrationQuery, {
    options: ({ match: { params: { id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network'
    })
  }),
  withTranslation()
)(Registration)
