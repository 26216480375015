import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { Form,
  Button,
  message,
  Input,
  Layout,
  Radio,
  InputNumber,
  Switch,
  Select
} from 'antd'
import EventSider from '~/components/Events/Event/Sider'
import TopContent from '~/components/Events/Event/TopContent'
import _get from 'lodash/get'
import _sortBy from 'lodash/sortBy'
import productQuery from '~/graphql/queries/product.gql'
import createProductMutation from '~/graphql/mutations/createProduct.gql'
import updateProductMutation from '~/graphql/mutations/updateProduct.gql'
import Loading from '~/components/Loading/index'
import EventNotFound from '~/components/Error/Eventnotfound'
import ProductNotFound from '~/components/Error/Productnotfound'
import { inject, observer } from 'mobx-react'
import withRepeaterHandler from '~/utils/withRepeaterHandler'
import Uploader from '~/components/Uploader'
import eventQuery from '~/graphql/queries/event.gql'
import Textarea from 'react-textarea-autosize'
import config from '~/config'
import { withTranslation } from 'react-i18next'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 }
  }
}
const toPercentage = (value) => {
  return value ? (value * 100).toFixed(2) : 0
}
@inject('router')
@observer
@Form.create()
@withRepeaterHandler
@graphql(createProductMutation, { name: 'createProduct' })
@graphql(updateProductMutation, { name: 'updateProduct' })
@graphql(productQuery, { // for edit
  options: ({ match: { params: { _id, eventId } } }) => ({
    variables: { _id, eventId },
    fetchPolicy: 'network-only'
  }),
  skip: ({ match: { params: { _id } } }) => !_id
})
@graphql(eventQuery, { // for create
  options: ({ match: { params: { eventId } } }) => ({
    variables: { _id: eventId }
  }),
  skip: ({ match: { params: { _id } } }) => !!_id
})
class ProductForm extends Component {
  state = {
    loading: false
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    const productId = _get(this.props, 'match.params._id')
    const eventId = _get(this.props, 'match.params.eventId')
    this.props.form.validateFieldsAndScroll((err, record) => {
      if (_get(record, 'chargePercentage') > 0) {
        record.chargePercentage = record.chargePercentage / 100
      }
      if (_get(record, 'fee') > 0) {
        record.fee = record.fee / 100
      }
      if (err) {
        message.error(err)
        this.setState({ loading: false })
        return
      }
      if (productId) {
        this.props.updateProduct({
          variables: {
            record
          }
        }).then(result => {
          message.success('Saved!')
          this.setState({ loading: false })
        }).catch(err => {
          message.error(err.message)
          this.setState({ loading: false })
          this.props.history.push(`/fetchPage?link=/events/${eventId}/products/${productId}/edit`)
        })
      } else {
        this.props.createProduct({
          variables: {
            record
          }
        }).then(result => {
          message.success('Saved!')
          this.setState({ loading: false })
          this.props.history.push(`/events/${eventId}/products`)
        }).catch(err => {
          message.error(err.message)
          this.setState({ loading: false })
        })
      }
    })
  }
  render () {
    const { data, t } = this.props
    const { product, event } = data
    const productId = _get(this.props, 'match.params._id')
    if (data.loading) return <Loading />
    if (!event) return <EventNotFound />
    if (!product && productId) return <ProductNotFound />
    const { getFieldDecorator } = this.props.form
    getFieldDecorator(`eventId`, {
      initialValue: _get(event, '_id')
    })
    getFieldDecorator(`_id`, {
      initialValue: _get(product, '_id')
    })
    return (<Layout>
      <Layout.Content>
        <TopContent title={_get(event, 'name.th') || _get(event, 'name.en')} />
          <div className="innerMainContentWrapper">
            <EventSider />
              <div className="middleContent">
                <Form>
                  <Input.Group key={`product`}>
                    <Form.Item {...formItemLayout}>
                      <span style={{fontSize: '18px'}}>
                        {productId ? 'Edit' : 'New'}  Product
                      </span>
                    </Form.Item>
                    <Form.Item label={`${t('products.new.name')} [EN]`} {...formItemLayout}>
                      {getFieldDecorator(`name.en`, {
                        initialValue: _get(product, 'name.en'),
                        rules: [{ required: true, message: 'Please input english name' }]
                      })(<Input/>)}
                    </Form.Item>
                    <Form.Item label={`${t('products.new.name')} [${config.default_locale.toUpperCase()}]`} {...formItemLayout}>
                      {getFieldDecorator(`name.${config.default_locale}`, {
                        initialValue: _get(product, `name.${config.default_locale}`),
                        rules: [{ required: true, message: `Please input ${config.default_locale} name` }]
                      })(<Input/>)}
                    </Form.Item>
                    <Form.Item label={`${t('products.new.description')} [EN]`} {...formItemLayout}>
                      {getFieldDecorator(`description.en`, {
                        initialValue: _get(product, 'description.en')
                      })(<Textarea style={{width: '100%'}}/>)}
                    </Form.Item>
                    <Form.Item label={`${t('products.new.description')} [${config.default_locale.toUpperCase()}]`} {...formItemLayout}>
                      {getFieldDecorator(`description.${config.default_locale}`, {
                        initialValue: _get(product, `description.${config.default_locale}`)
                      })(<Textarea style={{width: '100%'}}/>)}
                    </Form.Item>
                    <Form.Item label={'Status'} {...formItemLayout}>
                      {getFieldDecorator(`hidden`, {
                        initialValue: _get(product, 'hidden', true),
                        rules: [{ required: true, message: 'Please select status' }]
                      })(
                        <Radio.Group key={`status`}>
                          <Radio.Button key="show" value={false}>
                          Show
                          </Radio.Button>
                          <Radio.Button key="hidden" value={true}>
                          Hidden
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label={t('products.new.merchandise')}>
                      {getFieldDecorator('crossSale', {
                        initialValue: _get(product, 'crossSale'),
                        valuePropName: 'checked'
                      })(
                        <Switch />
                      )}
                    </Form.Item>
                    {config.hiddenField &&
                    <>
                    <Form.Item {...formItemLayout} label="เปิดประเภทสินค้า">
                      {getFieldDecorator('openorderitemtype', {
                        initialValue: _get(product, 'openorderitemtype'),
                        valuePropName: 'checked'
                      })(
                        <Switch />
                      )}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="ขายหลังจากสมัครเสร็จ">
                      {getFieldDecorator('extraSale', {
                        initialValue: _get(product, 'extraSale'),
                        valuePropName: 'checked'
                      })(
                        <Switch />
                      )}
                    </Form.Item>
                    </>
                    }
                    <i>{t('products.new.limitProduct')}</i>
                    <Form.Item
                      {...formItemLayout}
                      label={t('products.new.productLimit')}
                      hasFeedback
                    >
                      {getFieldDecorator('quantity', {
                        initialValue: _get(product, 'quantity', 0)
                      })(
                        <InputNumber min={0} step={1}/>
                      )}
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout}
                      label={t('products.new.minProduct')}
                      hasFeedback
                    >
                      {getFieldDecorator('minItems', {
                        initialValue: _get(product, 'minItems', 1)
                      })(
                        <InputNumber min={1} step={1}/>
                      )}
                    </Form.Item>
                    {/* <i>*จำกัดจำนวน: 0 แปลว่า "จำกัดสินค้าต่อใบสมัคร"</i> */}
                    <Form.Item
                      {...formItemLayout}
                      label={t('products.new.stockLimit')}
                      hasFeedback
                    >
                      {getFieldDecorator('maxItemsPerRegistration', {
                        initialValue: _get(product, 'maxItemsPerRegistration', 1000)
                      })(
                        <InputNumber min={1} step={1}/>
                      )}
                    </Form.Item>
                    <Form.Item label={'Image Url'} {...formItemLayout}>
                      {getFieldDecorator(`imageUrl`, {
                        initialValue: _get(product, 'imageUrl'),
                        rules: [{ required: false, message: 'Please input image url' }]
                      })(<Uploader />)}
                    </Form.Item>
                    <Form.Item label={'Price'} {...formItemLayout}>
                      {getFieldDecorator(`price`, {
                        initialValue: _get(product, 'price'),
                        rules: [{ required: true, message: 'Please input price' }]
                      })(<Input/>)}
                    </Form.Item>
                    <Form.Item label={t('products.new.propertyQuestion')} {...formItemLayout}>
                      {getFieldDecorator(`questionIds`, {
                        initialValue: _get(product, 'questionIds')
                      })(<Select
                        mode="multiple"
                        size='default'
                        style={{ width: '100%' }}
                      >
                        {_sortBy(_get(event, 'ticketQuestions', []), ['order']).map(q =>
                          <Select.Option key={q._id} value={q._id}>
                            [{q.order}] {_get(q, 'name.th')}
                          </Select.Option>
                        )}
                      </Select>)}
                    </Form.Item>
                    {/* <Form.Item label='ค่า Fee' {...formItemLayout}>
                      {getFieldDecorator(`fee`, {
                        initialValue: toPercentage(_get(product, 'fee', 0))
                      })(
                        <InputNumber min={0} max={100}/>
                      )} %
                    </Form.Item> */}
                    <Form.Item label={t('products.new.withHold')} {...formItemLayout}>
                      {getFieldDecorator(`chargePercentage`, {
                        initialValue: toPercentage(_get(product, 'chargePercentage', 0))
                      })(
                        <InputNumber min={0} max={100}/>
                      )} %
                    </Form.Item>
                    <Form.Item label={t('products.new.withHoldper')} {...formItemLayout}>
                      {getFieldDecorator(`chargePerItem`, {
                        initialValue: _get(product, 'chargePerItem', 0)
                      })(
                        <InputNumber min={0}/>
                      )} {config.platform === 'mas' ? 'MYR' : 'บาท'}
                    </Form.Item>
                  </Input.Group>
                  <Form.Item label={`Custom message [${config.default_locale.toUpperCase()}]`} {...formItemLayout}>
                    {getFieldDecorator(`customMessage.${config.default_locale}`, {
                      initialValue: _get(product, `customMessage.${config.default_locale}`)
                    })(
                      <Textarea style={{width: '100%'}}/>
                    )}
                  </Form.Item>
                  <Form.Item label={`Custom message [EN]`} {...formItemLayout}>
                    {getFieldDecorator(`customMessage.en`, {
                      initialValue: _get(product, 'customMessage.en')
                    })(
                      <Textarea style={{width: '100%'}}/>
                    )}
                  </Form.Item>
                  <Form.Item style={{textAlign: 'center'}}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={this.handleSubmit}
                      loading={this.state.loading}
                    >
                      Save
                    </Button>{' '}
                    <Button loading={this.state.loading} type="ghost" onClick={() => window.history.back()}>
                      Back
                    </Button>
                  </Form.Item>
                </Form>
            </div>
          </div>
        </Layout.Content>
      </Layout>)
  }
}

export default withTranslation() (ProductForm)
