import React, {Component} from 'react'
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import _get from 'lodash/get'
import arrayMove from 'array-move'
import { Button, Modal } from 'antd'
import config from '~/config'
const locale = config.default_locale || 'th'
const SortableItem = SortableElement(({value, backgroundColor}) => <div
  className="item"
  style={{backgroundColor}}>
  {value}
</div>)

const SortableList = SortableContainer(({items, itemTypes, keyType}) => {
  // console.log({items, itemTypes, keyType})
  return (
    <div className="sortableItems">
      {items.map((item, index) => {
        let itemType
        if (itemTypes && keyType) {
          itemType = itemTypes.find(t => t._id === _get(item, keyType))
        }
        let value = `[${item.name[locale]}]`
        if (itemType) {
          value = value + `, [${itemType.name[locale]}]`
        }
        return <SortableItem key={`item-${index}`} index={index} value={value} backgroundColor={_get(itemType, 'backgroundColor')} />
      })}
    </div>
  )
})
class SortItems extends Component {
  state = {
    items: this.props.items,
    submitting: false
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({items}) => ({
      items: arrayMove(items, oldIndex, newIndex)
    }))
  }
  handleSubmit = async () => {
    const { updateOrderItems, toPath
      // , refetch
      , history } = this.props
    this.setState({ submitting: true })
    const sortItems = this.state.items.map((item, index) => {
      updateOrderItems({
        variables: {
          record: {
            _id: item._id,
            order: index
          }
        }
      })
      return item
    })
    try {
      await Promise.all(sortItems)
      Modal.success({
        title: 'Updated',
        onOk: () => {
          this.setState({ submitting: false })
          // refetch()
          history.push(`/fetchPage?link=${toPath}`)
          // console.log({toPath})
          // history.goBack()
        }
      })
    } catch (err) {
      this.setState({ submitting: false })
      Modal.error({
        title: 'Error',
        content: `${err}`,
        onOk: () => {
        }
      })
    }
  }
  render () {
    const {history} = this.props
    return <div>
      <i>*ลากและกดบันทึก</i>
      <SortableList items={this.state.items} itemTypes={this.props.itemTypes} keyType={this.props.keyType} onSortEnd={this.onSortEnd} />
      <Button
        onClick={this.handleSubmit}
        type="primary"
        loading={this.state.submitting}
        icon="save"
      >
        บันทึก
      </Button>{` `}
      <Button
        onClick={() => {
          history.replace()
        }}
        loading={this.state.submitting}
      >
        กลับ
      </Button>
    </div>
  }
}

export default SortItems
