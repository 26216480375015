import React, { Component } from 'react'
import { graphql, withApollo } from 'react-apollo'
// eslint-disable-next-line
import { Link, withRouter } from 'react-router-dom'
import { Layout, Table, Button, Dropdown, Menu, Input, Select, Modal, message, Divider } from 'antd'
import _get from 'lodash/get'
import _pickBy from 'lodash/pickBy'
import moment from 'moment-timezone'
import { withTranslation } from 'react-i18next'
// import qs from 'qs'
import EventSider from '~/components/Events/Event/Sider'
import TransferModal from '~/components/TransferModal'
import SendEmailModal from '~/components/SendEmailModal'
import TopContent from '~/components/Events/Event/TopContent'
// import eventRegistrationsQuery from '~/graphql/queries/eventRegistrations.gql'
import eventSearchRegistrationsQuery from '~/graphql/queries/eventSearchRegistrations.gql'
import payRegistrationMutation from '~/graphql/mutations/payRegistration.gql'
import sendRegistrationSubmissionEmailMutation from '~/graphql/mutations/sendRegistrationSubmissionEmail.gql'
import sendRegistrationPaymentEmailMutation from '~/graphql/mutations/sendRegistrationPaymentEmail.gql'
import sendLottoResultEmailMutation from '~/graphql/mutations/sendLottoResultEmail.gql'
import removeAllTestModeOnEventMutation from '~/graphql/mutations/removeAllTestModeOnEvent.gql'
import fixOffsiteStatusOnEventMutation from '~/graphql/mutations/fixOffsiteStatusOnEvent.gql'
import extendExpiryByHoursMutation from '~/graphql/mutations/extendExpiryByHours.gql'
import eventRegistrationExportMutation from '~/graphql/mutations/eventRegistrationExport.gql'
import deleteDuplicateParticipants from '~/graphql/mutations/deleteDuplicateParticipants.gql'
import deleteSingleParticipantMutation from '~/graphql/mutations/deleteSingleParticipant.gql'
import { inject, observer } from 'mobx-react'
import Itag from '~/components/Itag'
import clientAuth from '~/utils/client-auth'
import _includes from 'lodash/includes'
// import _get from 'lodash/get'
import _defaultTo from 'lodash/defaultTo'

// import '../styles/responsive-table.css'
import '~/styles/EventRegistrations.css'
import Loading from '~/components/Loading'
import EventNotFound from '~/components/Error/Eventnotfound'

const getProfileType = ({user}) => {
  const isFB = _get(user, 'profiles.facebook')
  let status = 'normal'
  if(isFB) {
    status = 'facebook'
  } else if(_defaultTo(user.email, '').search('raceline+') > -1) {
    status = 'LINE'
  }
  return <Itag type={status} text={status} />
}

@inject('router')
@withApollo
@graphql(deleteDuplicateParticipants, { name: 'deleteDuplicateParticipants' })
@graphql(eventSearchRegistrationsQuery, {
  options: ({
    match: {
      params: { eventId }
    },
    router: {
      location: {
        query: { s: search, status, p: page }
      }
    }
  }) => ({
    variables: {
      eventId,
      search,
      status,
      page
    },
    fetchPolicy: 'network-only'
  })
  // ,skip: (data) => data.location.search === "" ? true : false
})
@graphql(payRegistrationMutation, {
  name: 'payRegistration'
})
@graphql(sendRegistrationSubmissionEmailMutation, {
  name: 'sendRegistrationSubmissionEmail'
})
@graphql(sendRegistrationPaymentEmailMutation, {
  name: 'sendRegistrationPaymentEmail'
})
@graphql(sendLottoResultEmailMutation, {
  name: 'sendLottoResultEmail'
})
@graphql(extendExpiryByHoursMutation, {
  name: 'extendExpiryByHours'
})
@graphql(removeAllTestModeOnEventMutation, {
  name: 'removeAllTestModeOnEvent'
})
@graphql(fixOffsiteStatusOnEventMutation, {
  name: 'fixOffsiteStatusOnEvent'
})
@observer
class EventRegistrations extends Component {
  state = {
    loading: false,
    showBankTransferModal: false,
    currentRegistration: null,
    emailMode: null,
    isMobile: window.innerWidth < 750
  }
  handleStatus = (status) => {
    const { location } = this.props.router
    const { p, ...query } = location.query
    location.query = _pickBy({
      ...query,
      status: status
    })
  }
  handleSearch = (search) => {
    const { location } = this.props.router
    const { p, ...query } = location.query
    location.query = {
      ...query,
      s: search
    }
  }
  handlePageChange = (page) => {
    const { location } = this.props.router
    location.query = {
      ...location.query,
      p: page
    }
  }
  extendExpiry24h = (registrationId) => {
    this.props.extendExpiryByHours({
      variables: { registrationId }
    }).then(this.props.data.refetch())
  }

  getHandleTransferCheckout = registration => checkoutData => {
    this.setState({
      loading: true
    })
    this.props
      .payRegistration({
        variables: {
          record: {
            registrationId: registration._id,
            userId: registration.userId,
            eventId: registration.eventId,
            method: 'transfer',
            data: {
              transfer: checkoutData
            }
          }
        }
      })
      .then(({ data }) => {
        this.setState(
          {
            loading: false
          },
          () => this.handleBankTransferModalClose()
        )
        // TODO: update registration
        // console.log(data)
        // const { registrationId } = _get(data, 'payRegistration.record')
      })
    // console.log(formData)
  }

  handleRegistrationBankTransferModalOpen = registration => {
    this.setState({
      showBankTransferModal: true,
      currentRegistration: registration
    })
  }

  handleBankTransferModalClose = e => {
    this.setState({
      showBankTransferModal: false,
      currentRegistration: null
    })
  }

  handleEmailModal = (emailMode, registration) => {
    this.setState({
      emailMode,
      currentRegistration: registration
    })
  }

  handleModalMobile = (key, registration) => {
    const lowerKey = key.toLowerCase()
    if (lowerKey === 'submission' || lowerKey === 'payment') {
      this.handleEmailModal(key, registration)
    }
    if (lowerKey === 'pay') {
      this.handleRegistrationBankTransferModalOpen(registration)
    }
    if (lowerKey === 'edit') {
      this.props.history.push(`/events/${this.props.data.event._id}/registrations/${registration._id}/edit`)
    }
  }

  handleSendEmail = ({ emailMode, registrationId, toEmail }) => {
    const sendFnName = ({
      submission: 'sendRegistrationSubmissionEmail',
      payment: 'sendRegistrationPaymentEmail',
      lotto: 'sendLottoResultEmail'
    })[emailMode]
    const sendFn = this.props[sendFnName]
    if (sendFn) {
      sendFn({
        variables: {
          _id: registrationId,
          options: {
            toEmail
          }
        }
      }).then(({ data }) => {
        message.info(`Email sent to ${toEmail} successfully`)
        this.handleEmailModalClose()
      })
    }
  }

  handleEmailModalClose = () => {
    this.setState({
      emailMode: null,
      currentRegistration: null
    })
  }
  handleRemoveTestMode = () => {
    const { data: {event} } = this.props
    Modal.confirm({
      title: 'Confirm delete',
      content: `Delete all testers ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.props.removeAllTestModeOnEvent({
          variables: {
            _id: event._id
          }
        }).then(res =>
          Modal.success({
            title: 'Deleted all testers',
            onOk: () => {
              this.props.history.push(`/fetchPage?link=/events/${event._id}/registrations`)
            }
          })
        ).catch(e => {
          Modal.error({
            title: 'Error',
            content: JSON.stringify(e),
            onOk: () => {
              this.setState({ loading: false })
            }
          })
        })
      },
      onCancel () {}
    })
  }
  handleDeleteDuplicates = () => {
    const { data: {event} } = this.props
    Modal.confirm({
      title: 'Confirm ลบชื่อซํ้า',
      content: `ลบชื่อซํ้าในใบสมัครเดียวกัน" ?`,
      onOk: () => {
        this.setState({ loading: true })
        this.props.deleteDuplicateParticipants({
          variables: {
            eventId: event._id
          }
        }).then(res =>
          Modal.success({
            title: 'Duplicates Deleted',
            onOk: () => {
              this.props.history.push(`/fetchPage?link=/events/${event._id}/registrations`)
            }
          })
        ).catch(e => {
          console.error({e})
          Modal.success({
            title: 'Duplicates Deleted',
            onOk: () => {
              this.props.history.push(`/fetchPage?link=/events/${event._id}/registrations`)
            }
          })
        })
      },
      onCancel () {}
    })
  }
  handlefixOffsiteStatusOnEvent = () => {
    const { data: {event} } = this.props
    Modal.confirm({
      title: 'Confirm อัพเดททุกใบสมัคร',
      content: (<div>
        อัพเดททุกใบสมัคร
        (จ่ายผ่าน <Itag type='linepay' text='linepay' />
        <Itag type='omise_netbank' text='omise_netbank' />
        <Itag type='omise_tesco' text='omise_tesco' />
        <Itag type='omise_truemoney' text='omise_truemoney' /> ที่จ่ายสำเร็จ)
         จากสถานะ <Itag type='submitted' text='submitted' /> เป็น <Itag type='paid' text='paid' /> ทุกใบ
        และ ส่ง Email การชำระเงินสำเร็จ
      </div>),
      onOk: () => {
        this.setState({ loading: true })
        this.props.fixOffsiteStatusOnEvent({
          variables: {
            _id: event._id
          }
        }).then(res =>
          Modal.success({
            title: 'Fixed off site status',
            onOk: () => {
              this.props.history.push(`/fetchPage?link=/events/${event._id}/registrations`)
            }
          })
        ).catch(e => {
          console.error({e})
          // Modal.error({
          //   title: 'Error',
          //   content: JSON.stringify(e),
          //   onOk: () => {
          //     this.setState({ loading: false })
          //   }
          // })
          Modal.success({
            title: 'Fixed off site status',
            onOk: () => {
              this.props.history.push(`/fetchPage?link=/events/${event._id}/registrations`)
            }
          })
        })
      },
      onCancel () {}
    })
  }
  handleExport = async () => {
    this.setState({ loading: true })
    const eventId = _get(this.props.match.params, 'eventId')
    if (eventId) {
      try {
        const { data, errors } = await this.props.client.mutate({
          mutation: eventRegistrationExportMutation,
          variables: { _id: eventId }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ loading: false })
          return
        }
        const url = _get(data, 'eventRegistrationExport.url')
        const fileName = _get(data, 'eventRegistrationExport.fileName')
        // console.log({ response })
        if (url) {
          Modal.success({
            width: 500,
            iconType: 'file-excel',
            content: <a href={url} download>
              <Button type="primary" icon="download">{fileName}</Button>
            </a>,
            style: { textAlign: 'center' },
            okText: 'Close',
            okType: 'default'
          })
        }
      } catch (err) {
        message.error(err.message)
      } finally {
        this.setState({ loading: false })
      }
    }
  }
  handleDeleteParticipant = async (participant) => {
    this.setState({ loading: true })
    Modal.confirm({
      title: 'Confirm delete',
      content: `Delete this participant?`,
      onOk: async () => {
        const { errors } = await this.props.client.mutate({
          mutation: deleteSingleParticipantMutation,
          variables: {
            participantId: participant._id
          }
        })
        if (errors) {
          // TODO: throw?
          errors.forEach(error => message.error(error.message))
          this.setState({ loading: false })
        } else {
          this.props.data.refetch()
          this.setState({ loading: false })
        }
      },
      onCancel () {
        this.setState({ loading: false })
      }
    })
  }
  render () {
    const { data } = this.props
    const { isMobile } = this.state
    const {roles} = clientAuth.login()
    const { t } = this.props
    if (_get(data , 'loading')) {
      return <Loading />
    }
    if (_get(data, 'error.networkError.type') === 'WriteError') {
      console.log(data)
      // window.location.reload()
    }
    // if (!_get(data , 'event' )) {
    //   return <EventNotFound />
    // }
    const { location } = this.props.router
    const { s: search, status } = location.query
    const registrations = _get(data, 'event.searchRegistrations.items')
    const pageInfo = _get(data, 'event.searchRegistrations.pageInfo') || {}
    const { currentPage, itemCount, perPage } = pageInfo
    const eventId = _get(data , 'event._id')
    const columns = [{
    //   title: 'ID',
    //   dataIndex: '_id',
    //   key: '_id',
    //   width: 100,
    //   fixed: 'left'
    // }, {
      // mobile: true,
      title: t('menu.registration.code'),
      dataIndex: 'code',
      key: 'code',
      width: isMobile ? 100 : 120,
      // fixed: 'left',
      render: (code, record) => <Link to={`/events/${eventId}/registrations/${record._id}`}>{code}</Link>
    }, {
      mobile: true,
      title: t('menu.registration.regisby'),
      // dataIndex: 'user',
      width: 200,
      key: 'user',
      // render: ({ user: { profile: { firstName, lastName }, email }, code }, record) => (
      render: (registration, record) => (
        <div>
          {isMobile && <div>รหัสใบสมัคร: <Link to={`/events/${eventId}/registrations/${record._id}`}>{registration.code}</Link></div>}
          {registration.user && <span style={{ whiteSpace: 'pre-line' }}>
            {`${registration.user.profile.firstName || ''} ${registration.user.profile.lastName || ''} \n (${_defaultTo(registration.user.email, registration.user.profile.telephone)} )`}
          </span>}
          <div>
            {registration.user && getProfileType({user: registration.user})}
          </div>
          <div style={{marginTop: 5}}>
            {(['submitted', 'payment_verifying', 'paid'].includes(registration.status)
              ? <Dropdown placement="topCenter" overlay={
                <Menu onClick={({ item, key, keyPath }) => isMobile ? this.handleModalMobile(key, registration) : this.handleEmailModal(key, registration)}>
                  <Menu.Item key="submission">
                      ยืนยัน
                  </Menu.Item>
                  {registration.status === 'paid' &&
                      <Menu.Item key="payment">
                        ชำระเงิน
                      </Menu.Item>
                  }
                  {isMobile && registration.status !== 'paid' && data.event.testMode && <Menu.Item key='pay'>จ่ายเงิน</Menu.Item>}
                  {isMobile && <Menu.Item key='edit'>แก้ไข</Menu.Item>}
                </Menu>}
              >
                <Button size="small" style={{ fontSize: 10, padding: '0 4px' }}>{t('menu.registration.action')}</Button>
              </Dropdown>
              : isMobile && <Dropdown placement="topCenter" overlay={
                <Menu onClick={({ item, key, keyPath }) => isMobile ? this.handleModalMobile(key, registration) : this.handleEmailModal(key, registration)}>
                  {registration.status === 'paid' &&
                      <Menu.Item key="payment">
                        การชำระเงิน
                      </Menu.Item>
                  }
                  {isMobile && registration.status !== 'paid' && data.event.testMode && <Menu.Item key='pay'>จ่ายเงิน</Menu.Item>}
                  {isMobile && <Menu.Item key='edit'>แก้ไข</Menu.Item>}
                </Menu>}
              >
                <Button size="small" style={{ fontSize: 10, padding: '0 4px' }}>{t('menu.registration.action')}</Button>
              </Dropdown>
            )}
          </div>
        </div>
      )
    }, {
      mobile: true,
      title: t('menu.registration.status'),
      key: 'status-discounted',
      width: isMobile ? 70 : 130,
      render: ({testMode, status, amount}) => {
        const discounted = _get(amount, 'discounted')
        return <div className={!isMobile ? 'mL' : null}>
          <Itag type={status} text={status} />
          {testMode && <Itag type='testMode' text='Test mode' />}
          {discounted > 0 && <Itag type='amount' text={`${t('menu.registration.price', {
            value: {
              price: discounted,
              currency: _get(data, 'event.settings.payment.currency', 'THB')
            },
          })}`} />}
        </div>
      }
    }, {
      title: t('menu.registration.participant'),
      // dataIndex: 'participants',
      key: 'participants',
      render: ({ participants, status }) => (
        <ul>
          {participants.map((participant, index) => {
            const ticket = participant.tickets.find(t => `${t.participantId}` === `${participant._id}`)
            return <li key={participant._id}>
              <Link to={`/events/${eventId}/participants/${participant._id}`}>
                {participant.profile.firstName} {participant.profile.lastName}
              </Link>
              <Divider type='vertical' />
              {index !== 0 && (
                !['paid', 'payment_pending', 'cancelled'].includes(status) ||
                !_get(ticket, 'ticketType') || _get(ticket, 'ticketType.price') === 0) &&
              // {participants.length > 1 && !['paid', 'payment_pending', 'cancelled'].includes(status) &&
                <Button type="link" onClick={() => this.handleDeleteParticipant(participant)}>Delete</Button>}
              <br/>
              {`(${participant.profile.email})`}
              {!_get(participant, 'event.settings.ticket.hideNumber') && <div>
                (BIB: {_get(participant, 'tickets').map(ticket => ticket.number).join(', ')})
              </div>}
            </li>
          })}
        </ul>
      )
      // }, {
      //   title: 'Pending Payments',
      //   // dataIndex: 'participants',
      //   key: 'pending_payments',
      //   render: ({ pendingPayments }) => <span>View</span>
      // }, {
      //   title: 'Completed Payments',
      //   // dataIndex: 'participants',
      //   key: 'completed_payments',
      //   render: ({ completedPayments }) => <span>View</span>
    }, {
      title: t('menu.registration.payment'),
      // dataIndex: 'participants',
      width: 130,
      key: 'completed_payments',
      render: ({ _id, payments }) => {
        return (
          <div>
            {payments.map(p => (
              <Itag
                key={p._id}
                type={p.status}
                text={p.method}
              >
                {p.method}
              </Itag>
            ))}
            <br />
            {payments.length > 0 && (
              <Link to={`/events/${eventId}/registrations/${_id}/payments`}>
                view all
              </Link>
            )}
          </div>
        )
      }
    }, {
      title: t('menu.registration.expire'),
      key: 'expiry',
      width: 120,
      render: ({ _id, status, expired, expireAt }) => (
        expired || !expireAt
          ? ['submitted', 'draft'].includes(status) && <Button type="danger" onClick={() => this.extendExpiry24h(_id)}>Extend 24h</Button>
          : status !== 'paid' && expireAt && <span>{moment(expireAt).format('DD/MM/YYYY HH:mm')}</span>
      )
    }, {
      title: t('menu.registration.action'),
      // dataIndex: 'actions',
      key: 'actions',
      width: 100,
      // fixed: 'right',
      render: record => {
        // const slipUrl = _get(record, 'data.transfer.slipUrl')
        const buttonStyle = {
          margin: '8px 0'
        }

        return (
          <span style={{textAlign: 'left'}}>
            {record.status !== 'paid' && (
              ( data.event.slug === 'heroes' && _includes(roles, 'admin') )
                || data.event.testMode || data.event.organizerId === '5a56aad18ec5f75108ac001d'
            ) && (
            // {record.status !== 'paid' && data.event.testMode &&(
              <Button
                style={buttonStyle}
                type="primary"
                // loading={this.state.confirming === record._id}
                onClick={() =>
                  this.handleRegistrationBankTransferModalOpen(record)}
              >
                Pay
              </Button>

            )}
            <Link to={`/events/${eventId}/registrations/${record._id}/edit`}>
              <Button className="edit" icon="edit">
                Edit
              </Button>
            </Link>
          </span>
        )
      }
    }, {
      // mobile: true,
      title: isMobile ? t('menu.registration.action') : t('menu.registration.sendmail'),
      key: isMobile ? t('menu.registration.action') : t('menu.registration.sendmail'),
      width: 80,
      // fixed: 'right',
      render: registration => (['submitted', 'payment_verifying', 'paid', 'accepted', 'rejected', 'draft', 'lotto_authorized'].includes(registration.status)
        ? <Dropdown placement="topCenter" overlay={
          <Menu onClick={({ item, key, keyPath }) => isMobile ? this.handleModalMobile(key, registration) : this.handleEmailModal(key, registration)}>
            {['submitted', 'payment_verifying', 'paid'].includes(registration.status) &&
              <Menu.Item key="submission">
                  {t('menu.registration.confirm')}
              </Menu.Item>
            }
            {registration.status === 'paid' &&
                <Menu.Item key="payment">
                  {t('menu.registration.paymentMail')}
                </Menu.Item>
            }
            {['accepted', 'rejected', 'draft', 'lotto_authorized'].includes(registration.status) &&
                <Menu.Item key="lotto">
                  lotto status: ['accepted', 'rejected', 'draft', 'lotto_authorized']
                </Menu.Item>
            }
            {isMobile && registration.status !== 'paid' && <Menu.Item key='pay'>Pay</Menu.Item>}
            {isMobile && <Menu.Item key='edit'>แก้ไข</Menu.Item>}
          </Menu>}
        >
          <Button icon={isMobile ? 'up' : 'mail'} />
        </Dropdown>
        : isMobile && <Dropdown placement="topCenter" overlay={
          <Menu onClick={({ item, key, keyPath }) => isMobile ? this.handleModalMobile(key, registration) : this.handleEmailModal(key, registration)}>
            {registration.status === 'paid' &&
                <Menu.Item key="payment">
                  Payment
                </Menu.Item>
            }
            {isMobile && registration.status !== 'paid' && data.event.testMode && <Menu.Item key='pay'>จ่ายเงิน</Menu.Item>}
            {isMobile && <Menu.Item key='edit'>แก้ไข</Menu.Item>}
          </Menu>}
        >
          <Button icon={isMobile ? 'up' : 'mail'} />
        </Dropdown>
      )
    }]

    const size = 'small'

    return (
      // <div>EventRegistrations</div>
      <Layout>
        <Layout.Content>
          <TopContent
            title={_get(data , 'event.name.th') || _get(data,'event.name.en')}
            nodes={{
              SelectStatus: <Select
                placeholder="Select Status"
                firstActiveValue={null}
                onChange={this.handleStatus}
                value={status}
                defaultValue={null}
                // style={{ width: 160 }}
                className="select-status"
              >
                <Select.Option key="all" value={null}>All</Select.Option>
                {/* {['blank', 'draft', 'submitted', 'payment_pending', 'payment_verifying', 'paid', 'cancelled', 'rejected'].map(status => */}
                {['blank', 'draft', 'submitted', 'pending', 'payment_pending', 'payment_verifying', 'paid', 'cancelled', 'accepted', 'rejected'].map(status =>
                  <Select.Option key={status} value={status}>{status}</Select.Option>
                )}
              </Select>,
              InputSearch: <Input.Search
                placeholder="Search..."
                defaultValue={search}
                onSearch={this.handleSearch}
                enterButton
                // style={{ width: 240 }}
                className="search-event"
              />,
              Node3: <div>
                {_get(data,'event.testMode') &&
                  <Button
                    onClick={this.handleRemoveTestMode}
                    icon="delete"
                    loading={this.state.loading}
                    className="delete removeTestModeBtn"
                    style={{marginRight: '5px'}}
                  >
                    Test mode
                  </Button>
                }
                {/* <Button
                  onClick={this.handlefixOffsiteStatusOnEvent}
                  icon="file-protect"
                  loading={this.state.loading}
                  className="edit"
                  style={{marginRight: '5px'}}
                >
                  อัพเดทสถานะ
                </Button> */}
                {/* {_includes(roles, 'admin') && <Button
                  onClick={this.handleDeleteDuplicates}
                  icon="delete"
                  loading={this.state.loading}
                  className="delete"
                  style={{marginRight: '5px'}}
                >
                  ลบชื่อซํ้า
                </Button>} */}
                {/* <Button
                  onClick={this.handleExport}
                  icon="export"
                  loading={this.state.loading}
                  className="export-event"
                >
                  Export */}
                {/* <CSVLink data={flatData} >Export as Excel</CSVLink> */}
                {/* </Button> */}
              </div>
            }}
            pattern='registrations'/>
          <div className="innerMainContentWrapper">
            <EventSider />
            {/* <SendAllEmailBtn {...{eventId}}/> */}
              <div className="middleContent">
                <Table
                  bordered={!isMobile}
                  columns={columns.filter(column => isMobile ? column.mobile : column)}
                  dataSource={registrations}
                  loading={_get(data , 'loading' )}
                  // expandedRowRender={isMobile ? ({status, amount}) => {
                  //   const discounted = _get(amount, 'discounted')
                  //   return <div>
                  //       <Itag type={status} text={status} />
                  //       {discounted > 0 && <Itag type='amount' text={`${discounted} THB`} />}
                  //     </div>
                  // }: null}
                  // onRowClick={onRowClick}
                  locale={{ emptyText: `${t('locale.notFound')}` }}
                  size={size}
                  rowKey={'_id'}
                  scroll={isMobile ? {} : { x: 1200, y: 800 }}
                  pagination={{
                    defaultPageSize: perPage,
                    showQuickJumper: true,
                    defaultCurrent: 1,
                    current: currentPage,
                    total: itemCount,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    onChange: this.handlePageChange
                  }}
                />
              </div>
            </div>
          </Layout.Content>
          <TransferModal
            visible={this.state.showBankTransferModal}
            loading={this.state.loading}
            onCheckout={this.getHandleTransferCheckout(
              this.state.currentRegistration
            )}
            onClose={this.handleBankTransferModalClose}
          />
          <SendEmailModal
            registration={this.state.currentRegistration}
            visible={!!this.state.emailMode}
            mode={this.state.emailMode}
            onSend={this.handleSendEmail}
            onClose={this.handleEmailModalClose}
          />
      </Layout>
    )
  }
}

export default withTranslation() (EventRegistrations)
